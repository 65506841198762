import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-status-modal',
  templateUrl: './status-modal.component.html'
})
export class StatusModalComponent {
  constructor(
    public dialogRef: DialogRef<boolean>,
    @Inject(DIALOG_DATA)
    public data: {
      title: string;
      icon: string;
      iconClass: string;
      message: string;
      cancelBtnText: string;
      confirmBtnText: string;
    }
  ) { }
}
