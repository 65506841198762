
import { LoginCredentials } from "../../../shared/interfaces/auth.interface";

export class Authenticate {
  static readonly type = '[Auth] Authenticate';
  constructor(public readonly payload: LoginCredentials) { }
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class GetProfile {
  static readonly type = '[Auth] Get Profile';
}
