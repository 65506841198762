import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, catchError, tap, throwError } from "rxjs";
import { UserClientService } from "../../../core/services/userClient.service";
import { ShopClientPointDto } from "../interfaces/points.interface";
import { GetUserShopMetrics } from "./points.actions";
import { PointsStateModel } from "./points.model";

@State<PointsStateModel>({
  name: 'points',
  defaults: {
    loading: false,
    points: [],
  }
})
@Injectable({ providedIn: 'root' })
export class PointsState {
  @Selector()
  static loading(state: PointsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static points(state: PointsStateModel): ShopClientPointDto[] | null | undefined {
    return state.points;
  }

  constructor(private userClientService: UserClientService) { }

  @Action(GetUserShopMetrics, { cancelUncompleted: true })
  getPointsByUserId(ctx: StateContext<PointsStateModel>, action: GetUserShopMetrics): Observable<ShopClientPointDto[]> {
    ctx.patchState({ points: [], loading: true });

    return this.userClientService.getUserShopMetrics(action.token).pipe(
      tap((points: any) => {
        ctx.patchState({
          points: points,
          loading: false
        });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }
}