import { Contact } from "../interfaces/landing-data.interface";

export class GetFaqs {
  static readonly type = '[Faqs] Get Faqs';
  constructor() { }
}

export class GetTestimonials {
  static readonly type = '[Testimonials] Get Testimonials';
  constructor() { }
}

export class GetStats {
  static readonly type = '[Stats] Get Stats';
  constructor() { }
}

export class SendContact {
  static readonly type = '[Contact] Send Contact';
  constructor(public readonly payload: Contact) { }
}
