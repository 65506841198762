import { getActionTypeFromInstance } from '@ngxs/store';
import { Logout } from './auth.actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logoutPlugin(state: unknown, action: unknown, next: any): unknown {
  // Use the get action type helper to determine the type
  if (getActionTypeFromInstance(action) === Logout.type) {
    // if we are a logout type, lets erase all the state
    state = {};
  }

  // return the next function with the empty state
  return next(state, action);
}
