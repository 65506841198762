import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//layouts
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/layouts/main-layout/main-layout.module').then((m) => m.MainLayoutModule)
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking', useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
