import { DEFAULT_DIALOG_CONFIG, DialogModule } from '@angular/cdk/dialog';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { logoutPlugin } from '@features/auth/state/logout.plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NGXS_PLUGINS, NgxsModule } from '@ngxs/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorsInterceptor } from './core/interceptors/error.interceptor';
import { AccountState } from './features/account/state/account.state';
import { AuthState } from './features/auth/state/auth.state';
import { LandingState } from './features/landing/state/landing.state';
import { PointsState } from './features/points/state/points.state';
import { PromotionsState } from './features/promotions/state/promotions.state';
import { PurchasesState } from './features/purchases/state/purchase.state';
import { ShopsState } from './features/shops/state/shop.state';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'BenefcaiteApp' }),
    NgxsModule.forRoot([AuthState, AccountState, PointsState, PurchasesState, ShopsState, LandingState, PromotionsState]),
    NgxsStoragePluginModule.forRoot({
      key: [AuthState]
    }),
    NgxsStoragePluginModule.forRoot({ storage: StorageOption.LocalStorage }),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    DialogModule,
    MatSelectModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: {
        autoFocus: false,
        backdropClass: ['bg-opacity-75', 'transition-opacity', 'bg-ms-black'],
        hasBackdrop: true
      }
    },
    {
      provide: NGXS_PLUGINS,
      useValue: logoutPlugin,
      multi: true
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5000
      }
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
