import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, catchError, tap, throwError } from "rxjs";
import { ShopsService } from "../../../core/services/shops.service";
import { ShopDto } from "../interfaces/shop.interface";
import { GetShops } from "./shop.actions";
import { ShopsStateModel } from "./shop.model";

@State<ShopsStateModel>({
  name: 'shops',
  defaults: {
    loading: false,
    shops: [],
  }
})
@Injectable({ providedIn: 'root' })
export class ShopsState {
  @Selector()
  static loading(state: ShopsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static shops(state: ShopsStateModel): ShopDto[] | null | undefined {
    return state.shops;
  }

  constructor(private shopsService: ShopsService) { }

  @Action(GetShops, { cancelUncompleted: true })
  getShops(ctx: StateContext<ShopsStateModel>, action: GetShops): Observable<ShopDto[]> {
    ctx.patchState({ shops: [], loading: true });

    return this.shopsService.getShops().pipe(
      tap((shops: any) => {
        ctx.patchState({
          shops: shops,
          loading: false
        });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }
}