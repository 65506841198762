import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, catchError, tap, throwError } from "rxjs";
import { UserClientService } from "../../../core/services/userClient.service";
import { PurchaseClient } from "../interfaces/purchase.interface";
import { GetPurchaseForClient, SendPurchase } from "./purchase.actions";
import { PurchasesStateModel } from "./purchase.model";

@State<PurchasesStateModel>({
  name: 'purchases',
  defaults: {
    loading: false,
    purchases: [],
    sendingPurchase: false,
  }
})
@Injectable({ providedIn: 'root' })
export class PurchasesState {
  @Selector()
  static loading(state: PurchasesStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static sendingPurchase(state: PurchasesStateModel): boolean {
    return state.sendingPurchase;
  }

  @Selector()
  static purchases(state: PurchasesStateModel): PurchaseClient[] | null | undefined {
    return state.purchases;
  }

  constructor(private userClientService: UserClientService) { }

  @Action(GetPurchaseForClient, { cancelUncompleted: true })
  getPurchaseForClient(ctx: StateContext<PurchasesStateModel>, action: GetPurchaseForClient): Observable<PurchaseClient[]> {
    ctx.patchState({ purchases: [], loading: true });

    return this.userClientService.getPurchaseClientList(action.token).pipe(
      tap((purchases: any) => {
        ctx.patchState({
          purchases: purchases,
          loading: false
        });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(SendPurchase, { cancelUncompleted: true })
  sendPurchase(ctx: StateContext<PurchasesStateModel>, action: SendPurchase): Observable<void> {
    ctx.patchState({ sendingPurchase: true });

    return this.userClientService.sendPurchase(action.payload).pipe(
      tap((response: any) => {
        ctx.patchState({
          sendingPurchase: false
        });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ sendingPurchase: false });
        return throwError(() => err);
      })
    );
  }

}