<div class="panel-basic-dialog p-6">
  <div>
    <div>
      <div
        *ngIf="data.iconClass && data.icon"
        [ngClass]="data.iconClass"
        class="my-0 mx-auto w-9 h-9 rounded-xl text-center leading-8">
        <i [ngClass]="data.icon" class="font-semibold text-ms-white text-ms-24 align-middle"></i>
      </div>
    </div>
    <div class="mt-3 text-center">
      <h2 class="font-bold text-ms-16 leading-6 text-ms-gray-900" id="modal-title">{{ data.title }}</h2>
      <div class="mt-2">
        <p class="text-ms-14 text-ms-gray-500" [innerHTML]="data.message"></p>
      </div>
    </div>
  </div>

  <div class="w-full inline-flex justify-center items-center space-x-4 mt-6">
    <button *ngIf="!!data.cancelBtnText" (click)="dialogRef.close()" type="button" class="w-1/2 ms-button-gray">
      {{ data.cancelBtnText }}
    </button>
    <button *ngIf="!!data.confirmBtnText" (click)="dialogRef.close(true)" type="button" class="w-1/2 ms-button-primary">
      {{ data.confirmBtnText }}
    </button>
  </div>
</div>
