import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ShopsService {
  constructor(private httpClient: HttpClient) { }

  getShops() {
    return this.httpClient.get(environment.url + 'shop/shops');
  }
}