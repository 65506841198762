import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BenefitDto } from '@shared/interfaces/dtos/benefitDto';
import { LevelDto } from '@shared/interfaces/dtos/levelDto';
import { UserClientDto } from '@shared/interfaces/dtos/userClientDto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CrossBenefitDto, PurchaseCrossBenefitDto } from '../../features/promotions/interfaces/promotion.interface';

@Injectable({ providedIn: 'root' })
export class DiscountService {
  constructor(private httpClient: HttpClient) { }

  checkDiscount(userDto: UserClientDto): Observable<BenefitDto> {
    return this.httpClient.post(environment.url + 'user-client/check-discounts', userDto);
  }

  getDiscount() {
    return this.httpClient.get(environment.url + 'user-client/discounts');
  }

  saveLevel(level: LevelDto) {
    return this.httpClient.put(environment.url + 'user-client/level', level);
  }

  getLevels() {
    return this.httpClient.get(environment.url + 'user-client/levels');
  }

  createDiscount(benefitDto: BenefitDto) {
    return this.httpClient.post(environment.url + 'user-client/discount', benefitDto);
  }

  deleteDiscount(id: string) {
    return this.httpClient.delete(environment.url + 'user-client/' + id + '/discount');
  }

  getShopsCrossBenefit(): Observable<object> {
    return this.httpClient.get(environment.url + 'discount/shops-cross-benefit');
  }

  getCrossBenefit(): Observable<CrossBenefitDto[]> {
    return this.httpClient.get<CrossBenefitDto[]>(environment.url + 'discount/cross-benefit');
  }

  purchaseCrossBenefit(data: PurchaseCrossBenefitDto): Observable<unknown> {
    return this.httpClient.post(environment.url + 'discount/purchase-cross-benefit', data);
  }

}
