import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthResponse, AuthResponseCustom } from '@shared/interfaces/auth.interface';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  jwtHelper = new JwtHelperService();

  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  setAuth(auth: AuthResponseCustom): void {
    if (this.isBrowser) localStorage.setItem('auth', JSON.stringify(auth));
  }

  getAuth(): string | null {
    if (this.isBrowser) return localStorage.getItem('auth');
    return null;
  }

  cleanStorage(): void {
    if (this.isBrowser) localStorage.clear();
  }
}
