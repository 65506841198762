import { CrossBenefitDto, PurchaseCrossBenefitDto } from "../interfaces/promotion.interface";

export class GetCrossBenefits {
  static readonly type = '[Cross Benefit] Get Cross Benefits';
}

export class GetBenefitFilters {
  static readonly type = '[Cross Benefit] Get Benefit Filters';
}

export class FilterCrossBenefits {
  static readonly type = '[Cross Benefit] Filter Cross Benefits';
  constructor(public readonly data: CrossBenefitDto[]) { }
}

export class PurchaseCrossBenefit {
  static readonly type = '[Cross Benefit] Purchase Cross Benefit';
  constructor(public readonly payload: PurchaseCrossBenefitDto) { }
}


