import { AutoPurchaseDto } from "../interfaces/purchase.interface";

export class GetPurchaseForClient {
  static readonly type = '[Purchase] Get Purchase For Client';
  constructor(public readonly token: string) { }
}

export class SendPurchase {
  static readonly type = '[Purchase] Send Purchase';
  constructor(public readonly payload: AutoPurchaseDto) { }
}