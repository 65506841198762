import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, catchError, tap, throwError } from "rxjs";
import { DiscountService } from "../../../core/services/discount.service";
import { CrossBenefitDto, ShopDto, ShopsCrossBenefitDto } from "../interfaces/promotion.interface";
import { FilterCrossBenefits, GetBenefitFilters, GetCrossBenefits, PurchaseCrossBenefit } from "./promotions.actions";
import { PromotionsStateModel } from "./promotions.model";

@State<PromotionsStateModel>({
  name: 'promotions',
  defaults: {
    loading: false,
    crossBenefits: [],
    filterBenefitShop: [],
    filterPointsShop: []
  }
})
@Injectable({ providedIn: 'root' })
export class PromotionsState {
  @Selector()
  static loading(state: PromotionsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static crossBenefits(state: PromotionsStateModel): CrossBenefitDto[] | null | undefined {
    return state.crossBenefits;
  }

  @Selector()
  static filterBenefitShop(state: PromotionsStateModel): ShopDto[] | null | undefined {
    return state.filterBenefitShop;
  }

  @Selector()
  static filterPointsShop(state: PromotionsStateModel): ShopDto[] | null | undefined {
    return state.filterPointsShop;
  }

  constructor(private discountService: DiscountService) { }

  @Action(GetCrossBenefits, { cancelUncompleted: true })
  getCrossBenefits(ctx: StateContext<PromotionsStateModel>, action: GetCrossBenefits): Observable<CrossBenefitDto[]> {
    ctx.patchState({ crossBenefits: [], loading: true });

    return this.discountService.getCrossBenefit().pipe(
      tap((response: any) => {
        ctx.patchState({
          crossBenefits: response,
          loading: false
        });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(GetBenefitFilters, { cancelUncompleted: true })
  getBenefitFilters(ctx: StateContext<PromotionsStateModel>, action: GetBenefitFilters): Observable<ShopsCrossBenefitDto> {
    ctx.patchState({ filterBenefitShop: [], filterPointsShop: [], loading: true });

    return this.discountService.getShopsCrossBenefit().pipe(
      tap((response: any) => {
        ctx.patchState({
          filterBenefitShop: response.benefitShop,
          filterPointsShop: response.pointsShop,
          loading: false
        });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(FilterCrossBenefits, { cancelUncompleted: true })
  filterCrossBenefits(ctx: StateContext<PromotionsStateModel>, action: FilterCrossBenefits): void {

    ctx.patchState({
      crossBenefits: action.data,
      loading: false
    });
  }

  @Action(PurchaseCrossBenefit, { cancelUncompleted: true })
  purchaseCrossBenefit(ctx: StateContext<PromotionsStateModel>, action: PurchaseCrossBenefit): Observable<unknown> {
    ctx.patchState({ loading: true });

    return this.discountService.purchaseCrossBenefit(action.payload).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }




}