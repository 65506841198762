import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Contact } from "../../features/landing/interfaces/landing-data.interface";

@Injectable({ providedIn: 'root' })
export class ContactService {
  constructor(private httpClient: HttpClient) { }

  send(contact: Contact): Observable<unknown> {
    return this.httpClient.post(environment.url + 'user-client/generate-contact', contact);
  }
}