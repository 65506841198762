import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, catchError, tap, throwError } from "rxjs";
import { ContactService } from "../../../core/services/contact.service";
import { FaqsService } from "../../../core/services/faqs.service";
import { StatisticsService } from "../../../core/services/statistics.service";
import { TestimonialsService } from "../../../core/services/testimonials.service";
import { Faq, Stats, Testimonial } from "../interfaces/landing-data.interface";
import { GetFaqs, GetStats, GetTestimonials, SendContact } from "./landing.actions";
import { LandingStateModel } from "./landing.model";

@State<LandingStateModel>({
  name: 'landing',
  defaults: {
    loadingFaqs: false,
    faqs: [],
    loadingTestimonials: false,
    testimonials: [],
    loadingStats: false,
    stats: null,
    sendingContact: false
  }
})
@Injectable({ providedIn: 'root' })
export class LandingState {
  @Selector()
  static loadingFaqs(state: LandingStateModel): boolean {
    return state.loadingFaqs;
  }

  @Selector()
  static faqs(state: LandingStateModel): Faq[] | null | undefined {
    return state.faqs;
  }

  @Selector()
  static loadingTestimonials(state: LandingStateModel): boolean {
    return state.loadingTestimonials;
  }

  @Selector()
  static testimonials(state: LandingStateModel): Testimonial[] | null | undefined {
    return state.testimonials;
  }

  @Selector()
  static loadingStats(state: LandingStateModel): boolean {
    return state.loadingStats;
  }

  @Selector()
  static stats(state: LandingStateModel): Stats | null | undefined {
    return state.stats;
  }

  @Selector()
  static sendingContact(state: LandingStateModel): boolean {
    return state.sendingContact;
  }

  constructor(private faqsService: FaqsService, private testimonialsService: TestimonialsService,
    private statisticsService: StatisticsService, private contactService: ContactService) { }

  @Action(GetFaqs, { cancelUncompleted: true })
  getFaqs(ctx: StateContext<LandingStateModel>, action: GetFaqs): Observable<Faq[]> {
    ctx.patchState({ faqs: [], loadingFaqs: true });

    return this.faqsService.getFaqs().pipe(
      tap((faqs: Faq[]) => {
        ctx.patchState({
          faqs: faqs,
          loadingFaqs: false
        });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loadingFaqs: false });
        return throwError(() => err);
      })
    );
  }

  @Action(GetTestimonials, { cancelUncompleted: true })
  getTestimonials(ctx: StateContext<LandingStateModel>, action: GetTestimonials): Observable<Testimonial[]> {
    ctx.patchState({ testimonials: [], loadingTestimonials: true });

    return this.testimonialsService.getTestimonials().pipe(
      tap((testimonials: Testimonial[]) => {
        ctx.patchState({
          testimonials: testimonials,
          loadingTestimonials: false
        });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loadingTestimonials: false });
        return throwError(() => err);
      })
    );
  }

  @Action(GetStats, { cancelUncompleted: true })
  getStats(ctx: StateContext<LandingStateModel>, action: GetStats): Observable<Stats> {
    ctx.patchState({ stats: null, loadingStats: true });

    return this.statisticsService.getStats().pipe(
      tap((stats: any) => {
        ctx.patchState({
          stats: stats,
          loadingStats: false
        });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loadingStats: false });
        return throwError(() => err);
      })
    );
  }

  @Action(SendContact, { cancelUncompleted: true })
  sendContact(ctx: StateContext<LandingStateModel>, action: SendContact): Observable<void> {
    ctx.patchState({ sendingContact: true });

    return this.contactService.send(action.payload).pipe(
      tap((response: any) => {
        ctx.patchState({
          sendingContact: false
        });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ sendingContact: false });
        return throwError(() => err);
      })
    );
  }
}