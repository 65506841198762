import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResponseCustom, LoginCredentials } from '@shared/interfaces/auth.interface';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private storage: LocalStorageService) { }

  login(credentials: LoginCredentials): Observable<AuthResponseCustom> {
    return this.http.get<AuthResponseCustom>(
      environment.url + 'user-client/my-login?Ci=' +
      credentials.ci + '&Password=' +
      credentials.password
    );
  }

  logout(): void {
    this.storage.cleanStorage();
  }

}
