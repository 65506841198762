import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, catchError, tap, throwError } from "rxjs";
import { LocalStorageService } from "../../../core/services/local-storage.service";
import { UserClientService } from "../../../core/services/userClient.service";
import { UserClientDto } from "../../../shared/interfaces/dtos/userClientDto";
import { CreateAccount, GetProfile, UpdateAccount } from "./account.actions";
import { AccountStateModel } from "./account.model";

@State<AccountStateModel>({
  name: 'account',
  defaults: {
    loading: false,
    profile: null,
  }
})
@Injectable({ providedIn: 'root' })
export class AccountState {
  @Selector()
  static loading(state: AccountStateModel): boolean | undefined {
    return state.loading;
  }

  @Selector()
  static profile(state: AccountStateModel): UserClientDto | null {
    return state.profile;
  }

  constructor(
    private userClientService: UserClientService,
    private router: Router,
    private localStorageService: LocalStorageService) { }

  @Action(CreateAccount, { cancelUncompleted: true })
  createAccount(ctx: StateContext<AccountStateModel>, action: CreateAccount): Observable<any> {
    ctx.patchState({ loading: true });

    return this.userClientService.createAccount(action.payload).pipe(
      tap((response: any) => {
        ctx.patchState({ loading: false });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(GetProfile, { cancelUncompleted: true })
  getProfile(ctx: StateContext<AccountStateModel>, action: GetProfile): Observable<UserClientDto> {
    ctx.patchState({ loading: true });
    return this.userClientService.getMyAccount(action.token).pipe(
      tap((account: any) => {
        ctx.patchState({ profile: account, loading: false });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(UpdateAccount, { cancelUncompleted: true })
  updateAccount(ctx: StateContext<AccountStateModel>, action: UpdateAccount): Observable<any> {
    ctx.patchState({ loading: true });

    return this.userClientService.updateAccount(action.payload).pipe(
      tap((response: any) => {
        ctx.patchState({ loading: false });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }
}