import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Faq } from '../../features/landing/interfaces/landing-data.interface';

@Injectable({ providedIn: 'root' })
export class FaqsService {
  constructor(private httpClient: HttpClient) { }

  getFaqs(): Observable<Faq[]> {
    //return this.httpClient.get(`${environment.identity}/api/account/my-profile`);

    return of([
      {
        id: 1,
        title: '¿Cómo funciona Beneficiate?',
        description: 'Sumas puntos Beneficiate, que luego cambiarás por cupones y descuentos en todas las tiendas adheridas',
        open: false
      },
      {
        id: 2,
        title: '¿Cómo acumulo Puntos?',
        description: 'Gestionas todos tus puntos y descuentos disponibles en una única plataforma',
        open: false
      },
      {
        id: 3,
        title: '¿Cómo canjeo mis puntos?',
        description: 'Dejá una calificación a tus marcas favoritas a cambio de puntos',
        open: false
      },
      {
        id: 4,
        title: '¿Cuáles son los locales adheridos?',
        description: 'Recomendale a conocidos tus marcas favoritas a cambio de puntos',
        open: false
      },
      {
        id: 5,
        title: '¿Dónde puedo ver los descuentos que tengo dispopnibles?',
        description: 'Suscribite a tus marcas favoritas para obtener un trato exclusivo',
        open: false
      },
      {
        id: 6,
        title: '¿Los puntos tienen vencimineto?',
        description: 'Pagá a través de la plataforma para sumar más puntos',
        open: false
      }
    ]);
  }
}