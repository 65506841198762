
import { UserClientDto } from "../../../shared/interfaces/dtos/userClientDto";

export class CreateAccount {
  static readonly type = '[Account] Create';
  constructor(public readonly payload: UserClientDto) { }
}

export class UpdateAccount {
  static readonly type = '[Account] Update';
  constructor(public readonly payload: UserClientDto) { }
}

export class GetProfile {
  static readonly type = '[Account] Get Profile';
  constructor(public readonly token: string) { }
}



