import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@core/services/local-storage.service';
import { Store } from '@ngxs/store';
import { Observable, catchError, throwError } from 'rxjs';
import { Logout } from '../../features/auth/state/auth.actions';
import { AuthState } from '../../features/auth/state/auth.state';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  refresh = false;

  constructor(public store: Store, private storageService: LocalStorageService, private router: Router) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let tokenType = '';

    const token = this.store.selectSnapshot(AuthState.accessToken);
    if (token) {
      request = this.addToken(request, token, tokenType);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.store.dispatch(new Logout())
          this.router.navigateByUrl('/auth/login');
        }
        return throwError(() => error);
      })
    );
  }

  private addToken(
    request: HttpRequest<unknown>,
    token: string | null,
    tokenType: string | null
  ): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `${tokenType} ${token}`
      }
    });
  }
}
